import React, { useContext, useEffect, useRef, useState } from 'react'
import HomeServices from '../components/HomeServices'
import ImagesSlide from '../components/ImagesSlide'
import styles from './home.module.css'
import { store } from '../components/HOC/State'
import { translate } from './translate'


export default function Home({children}) {
  let arr = [
    {
      image: "/mergelyan-building.jpg",
      text: "Founded in 1956 and named after the esteemed scientist Sergey Mergelyan, the Yerevan Computer Research Development Institute (YCRDI) has built a remarkable legacy in the field of computing technology.",
      color: "#162335",
    },
    {
      image: "/SergeyMergelyan.jpg",
      text: "With a rich history in designing and manufacturing a diverse array of computer technologies and systems, YCRDI has consistently been at the forefront of innovation. As a forward-thinking organization, we leverage our extensive expertise to embrace emerging technologies that drive advancements in both military and industrial sectors.",
      color: "gray",
    },
    {
      image: "/building.jpg",
      text: "By focusing on innovation and security, we aim to deliver cutting-edge solutions that uphold the highest standards of technological excellence. Yerevan Computer Research Development Institute – Secure Today Safe Tomorrow.",
      color: "#2c507b",
    }
  ]
    
    const [currentIndex, setCurrentIndex] = useState(0)
    const [fade, setFade] = useState(true)
    const {selectedLang} = useContext(store)
    let lang = translate[selectedLang]

    const ref = useRef()

    // let a = 0

    // useEffect(() => {
    //   let timer = 
    //   setInterval(() => {
    //     console.log(1)
    //     a == 2 ? a = 0 : a = a + 1
    //     setAnimation(arr[a])
    //   }, 1000)

    //   return () => clearInterval(timer)
    // }, [])

    useEffect(() => {
      const interval = setInterval(() => {
          setCurrentIndex(p => (p + 1) % arr.length)
        }, 7000)
       
      return () => clearInterval(interval)
    }, [])

    useEffect(() => {
      const objectElement = ref.current.querySelectorAll('object')

      console.log(objectElement)

      const loaded = (e) => {
        const object = e.contentDocument
        if(object){
          const path = object.querySelectorAll("path")
          console.log(path)
          path.forEach((e) => e.setAttribute('fill', '#2c507b'))
        }
      }

      objectElement.forEach(e => e.addEventListener('load', () => loaded(e)))

      return () => {
        objectElement.forEach(e => e.removeEventListener('load', () => loaded(e)))
      }
    }, [])


  return (
    <div>
      <div className={styles['home-div']}>
        <div>
            {/* <img src='/fon.webp' alt='main' className={styles['fon-image']}/> */}
            <div className={styles['on-animation']} style={{backgroundColor: arr[currentIndex].color, transition: "background-color 0.5s ease-in-out"}}>
                <div className={styles['name-h']}>
                  {/* <div className={styles['logo-image-div']}>
                    <img className={styles['logo-image']} src="/logo.svg" alt="logo" />
                  </div>
                  <div>
                      <h1 >{lang.tr_nameOfOrganization}</h1>
                      <h1>{lang.tr_nameOfOrganization2}</h1>
                  </div> */}
                  <h3 >
                      {arr[currentIndex].text}
                  </h3>
                  {/* <h3>
                     By being a forward-thinking company and leveraging its rich legacy,  YCRDI  is embracing new technologies and driving innovations to contribute to the military and industrial sectors. 
                  </h3> */}
                </div>

                {/* <div className={styles['image-back']}>
                    <img src='/mergelyan-building.jpg' alt="nkar"/>
                </div> */}
                <div className={styles['image-back']} style={{backgroundImage: `url(\"${arr[currentIndex].image}\")`, backgroundSize: "100% 100%", backgroundPosition: "top", transition: "background-image 0.5s ease-in-out"}}>
                    {/* <img src='/SergeyMergelyan.png' alt="nkar"/> */}
                </div>
            </div>
        </div>
        {/* <HomeServices /> */}
        {/* {children} */}
      </div>
      <div className={styles['offers']} >
        <h2>What we offer</h2>
        <div className={styles['offers-list']} ref={ref}>
          <div className={styles['offers-each']}>
              <p>Cryptographic</p>
              <p>Devices</p>
              <object type="image/svg+xml" data="/crypto.svg" width="100" height="100" >
                  Ваш браузер не поддерживает отображение SVG.
              </object>
              {/* <img src="/crypto.svg" alt="crypto" /> */}
          </div>
          <div className={styles['offers-each']}>
            <p>Shooting</p>
            <p>Simulators</p>
            <object type="image/svg+xml" data="/shooting.svg" width="100" height="100" >
                  Ваш браузер не поддерживает отображение SVG.
            </object>
            {/* <img src="/shooting.png" alt="shooting" /> */}
          </div>
          <div className={styles['offers-each']}>
            <p>Audio-Video</p>
            <p>Conference</p>
            <object type="image/svg+xml" data="/conf.svg" width="100" height="100" >
                  Ваш браузер не поддерживает отображение SVG.
            </object>
            {/* <img src="/conf.png" alt="conference" /> */}
          </div>
        </div>
      </div>
      <ImagesSlide />
    </div>
    
  )
}
