import React from 'react'
import { Link, useLocation } from 'react-router-dom';

import Search from './Search'
import Lang from './Lang/Lang'

import styles from './header.module.css'
import Menu from '../Menu/Menu';

export default function HeaderSide({atTop}) {

    const {pathname} = useLocation()

  return (
    <div className={atTop && pathname === "/" ? styles['header-div'] : styles['header-div-main']}>
    {<Link to='/' className={styles['logo-div']}>
            <img className={styles['logo-image']} src="/logo.svg" alt="logo" />
            <div className={styles['name-div']}>
                <h1 className={styles['name-h']}>YCRDI</h1>
            </div>
        </Link>}
        
        <div className={styles['search-lang-end']}>
            <div className={styles['search-lang-div']}>
                <Menu/>
                <Search />
                <Lang/>
            </div>
        </div>
    </div>
  )
}
